import React, { useContext, useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { styled } from '@compiled/react';

import { Box } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { PageCommentEditor, type CommentSaveHandler } from '@confluence/comment-simple';
import {
	ReactionsContext,
	useCommentsContentState,
	useCommentsContentActions,
} from '@confluence/comment-context';
import {
	ADD_PAGE_COMMENT_EXPERIENCE,
	ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import type { GraphQLContentStatus } from '@confluence/comments-panel-queries';
import { CreateGeneralCommentMutation } from '@confluence/page-comments-queries/entry-points/CreateGeneralCommentMutation.graphql';
import {
	Platform,
	ContentRepresentation,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralCommentMutation';
import type {
	CreateGeneralCommentMutationData,
	CreateGeneralCommentMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralCommentMutation';
import { updateApolloCacheParentCallback } from '@confluence/page-comments-queries/entry-points/pageCommentUtils';
import { usePageContentId, useContentType } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { useSearchSessionId } from '@confluence/search-session';
import { CommentType, useCommentsDataActions } from '@confluence/comments-data';
import type { CommentData } from '@confluence/comments-data';
import { useUnreadCommentsActions } from '@confluence/unread-comments';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { END } from '@confluence/navdex';
import { useCommentsPanelScroll } from '@confluence/comments-panel-utils';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { CommentAddPrompt } from './CommentAddPrompt';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterWrapperContainer = styled.div({
	minHeight: 40,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div.ak-editor-content-area': {
		overflowY: 'scroll',
		height: 95,
	},
});

enum CommentMode {
	VIEW = 'view',
	ADD = 'add',
}

export const CommentsPanelFooter = () => {
	const [commentMode, setCommentMode] = useState(CommentMode.VIEW);
	const shouldAutofocusPromptRef = useRef<boolean>(false);

	const [createGeneralCommentFn] = useMutation<
		CreateGeneralCommentMutationData,
		CreateGeneralCommentMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateGeneralCommentMutation,
	);

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isReactionsEnabled } = useContext(ReactionsContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { cloudId } = useSessionData();
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const pageMode = useGetPageMode();
	const [{ searchSessionId }] = useSearchSessionId();
	const { hasContentChanged } = useCommentsContentState();
	const { onChange, resetContentChanged } = useCommentsContentActions();
	const { addNewCommentThreads } = useCommentsDataActions();
	const { updateReadCommentsListState } = useUnreadCommentsActions();
	const { scrollCommentsPanelToThread } = useCommentsPanelScroll();

	const handleCommentsPanelSave: CommentSaveHandler = (adfObject, onSuccess) => {
		const pageId = contentId || '';
		const { adf } = adfObject as any;

		return createGeneralCommentFn({
			variables: {
				pageId,
				cloudId,
				input: {
					commentBody: {
						value: JSON.stringify(adf),
						representationFormat: ContentRepresentation.ATLAS_DOC_FORMAT,
					},
					commentSource: Platform.WEB,
					containerId: pageId,
				},
			},
			update: updateApolloCacheParentCallback(
				'create',
				{
					pageId,
					contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
				},
				isReactionsEnabled,
			),
		}).then(({ data }: any) => {
			const commentResult = data.createFooterComment;
			const comment: CommentData = {
				...commentResult,
				replies: [], // not included in the payload
				isUnread: false,
				isOpen: true,
				type: CommentType.GENERAL,
			};

			updateReadCommentsListState((prev) => new Set([...prev, comment.id]));
			addNewCommentThreads({ inline: {}, general: { [comment.id]: comment } });

			onSuccess && onSuccess();
			resetContentChanged();
			setCommentMode(CommentMode.VIEW);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'created',
					actionSubject: 'comment',
					actionSubjectId: comment.id, // The newly created comment ID
					objectType: contentType,
					objectId: contentId,
					source: AnalyticsSource.COMMENTS_PANEL,
					attributes: {
						commentType: 'page',
						pageType: contentType,
						isLivePage: pageMode === PageMode.LIVE,
						editor: 'v2',
						searchSessionId,
						navdexPointType: END,
						parentCommentId: null, // analytics event schema type expects string or null
					},
				},
			}).fire();

			experienceTracker.succeed({
				name: ADD_PAGE_COMMENT_EXPERIENCE,
			});

			setTimeout(() => {
				scrollCommentsPanelToThread(comment.id);
			}, 1000);
		});
	};

	const handleCancel = ({ isEditorAborted }: { isEditorAborted: boolean }) => {
		const doCancel = () => {
			if (isEditorAborted) {
				shouldAutofocusPromptRef.current = true;
			}

			setCommentMode(CommentMode.VIEW);
		};

		if (hasContentChanged) {
			resetContentChanged();
		}
		doCancel();
	};

	const handleOnEditorLoaded = () => {
		experienceTracker.succeed({
			name: ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
		});
	};

	return (
		<FooterWrapperContainer>
			{commentMode === CommentMode.VIEW ? (
				<CommentAddPrompt
					onClick={() => {
						setCommentMode(CommentMode.ADD);
					}}
					autofocusRef={shouldAutofocusPromptRef}
				/>
			) : (
				<Box>
					<PageCommentEditor
						mode="create"
						saveHandler={handleCommentsPanelSave}
						cancelHandler={handleCancel}
						onEditorLoaded={handleOnEditorLoaded}
						onEditorContentChanged={onChange}
						useNewExperienceAbort
						expandEditor
						isCommentsPanel
					/>
				</Box>
			)}
		</FooterWrapperContainer>
	);
};
