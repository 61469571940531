import React, { useEffect, useMemo } from 'react';
import { css } from '@compiled/react';

import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { CommentData } from '@confluence/comments-data';
import type { FlagsStateContainer } from '@confluence/flags';
import { CommentBatchList } from '@confluence/comment/entry-points/CommentBatchList';
import {
	useCommentsPanel,
	getSortedCommentThreads,
	SortValues,
} from '@confluence/comments-panel-utils';

import { getCommentThreadsToBatch } from '../helper/commentThreadHelper';

import { CommentThread } from './CommentThread';

type CommentPanelListProps = {
	commentThreads: CommentData[];
	supportedTopLevelActions: CommentAction[];
	flags?: FlagsStateContainer;
};

const listStyles = css({
	overflowY: 'auto',
	overflowX: 'hidden',
	flexGrow: 1,
	display: 'block',
});

// this component holds a list of comments
export const CommentsPanelList = ({
	commentThreads,
	supportedTopLevelActions,
	flags,
}: CommentPanelListProps) => {
	const pageMode = useGetPageMode();
	const [{ currentSort }, { setCurrentlyRenderedThreads }] = useCommentsPanel();

	const eventEmitter =
		pageMode === PageMode.EDIT || pageMode === PageMode.LIVE
			? getEditorAnnotationEventEmitter()
			: getRendererAnnotationEventEmitter();

	const sortedCommentThreads = useMemo(
		() => getSortedCommentThreads(currentSort, commentThreads),
		[commentThreads, currentSort],
	);

	// Whenever the list that we're rendering here changes, we want to update the list that the keyboard shortcuts are listening to
	useEffect(() => {
		setCurrentlyRenderedThreads(
			sortedCommentThreads.map((thread) => ({ ...thread.location, id: thread.id })),
		);

		return () => {
			// When this component is unmounted, we want to clear the list that the keyboard shortcuts are listening to
			setCurrentlyRenderedThreads([]);
		};
	}, [sortedCommentThreads, setCurrentlyRenderedThreads]);

	const actionsToShowUsers =
		pageMode === PageMode.EDIT
			? supportedTopLevelActions.filter((action) => action !== 'delete')
			: supportedTopLevelActions;

	// Props we want the batch renderer to pass through that aren't comment specific
	const passThroughProps = {
		// When sorting by MOST_RECENT, the comment type separator should never be rendered
		...(currentSort === SortValues.MOST_RECENT ? { shouldRenderCommentTypeSeparator: false } : {}),
		supportedTopLevelActions: actionsToShowUsers,
		eventEmitter,
		pageMode,
		flags,
	};

	return (
		<div
			id="comments-panel-list-container"
			data-testid="comments-panel-list-container"
			css={listStyles}
		>
			<CommentBatchList
				commentsList={sortedCommentThreads}
				passThroughProps={passThroughProps}
				Component={CommentThread}
				getElementsToBatch={getCommentThreadsToBatch}
			/>
		</div>
	);
};
