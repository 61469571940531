import React from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import DropdownMenu, {
	DropdownItem,
	DropdownItemCheckbox,
	DropdownItemGroup,
	DropdownItemCheckboxGroup,
} from '@atlaskit/dropdown-menu';
import { IconButton } from '@atlaskit/button/new';
import FilterIcon from '@atlaskit/icon/core/filter';

import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { fg } from '@confluence/feature-gating';
import { useCommentsContentState, useCommentsContentActions } from '@confluence/comment-context';
import type { ViewValues } from '@confluence/comments-panel-utils';
import { useCommentsPanel, SortValues } from '@confluence/comments-panel-utils';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { viewOptions, type ViewFilter } from './CommentsPanel';
import { CommentSortOptions } from './components/CommentSortOptions';

const i18n = defineMessages({
	filterAndSortLabel: {
		id: 'comments-panel.filter.and.sort.label',
		defaultMessage: 'Filter and sort',
		description: 'Label for button apply filters and sorting to comment panel',
	},
	filterTitle: {
		id: 'comments-panel.filter.title',
		defaultMessage: 'Filter',
		description: 'A title for the filter section',
	},
	showTitle: {
		id: 'comments-panel.show.title',
		defaultMessage: 'Show',
		description: 'A title for show section',
	},
	inlineCommentsLabel: {
		id: 'comments-panel.inline-comments.label',
		defaultMessage: 'Inline comments',
		description: 'Label for the show inline comments dropdown option',
	},
	generalCommentsLabel: {
		id: 'comments-panel.general-comments.label',
		defaultMessage: 'General comments',
		description: 'Label for the show general comments dropdown option',
	},
});

const dropdownItemStyles = css({
	paddingLeft: `${token('space.200')}`,
});

const dropDownMenuStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingBottom: 'space.050',
	paddingLeft: 'space.300',
	paddingTop: 'space.050',
	gap: 'space.100',
});

export const CommentsPanelFilterMenu: FC = () => {
	const { formatMessage } = useIntl();
	const { showModal } = useDialogs();
	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();
	const [
		{ currentView, showInlineComments, showGeneralComments, currentSort },
		{ setCurrentView, setShowInlineComments, setShowGeneralComments },
	] = useCommentsPanel();

	const updateView = (view: ViewValues) => {
		const handleClick = () => {
			// Do nothing if the same view is clicked
			if (currentView !== view) {
				setCurrentView && setCurrentView(view);
			}
		};

		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					handleClick();
				},
			});
		} else {
			handleClick();
		}
	};

	const toggleShow = (type: 'inline' | 'general') => {
		if (type === 'inline') {
			setShowInlineComments && setShowInlineComments(!showInlineComments);
		} else if (type === 'general') {
			setShowGeneralComments && setShowGeneralComments(!showGeneralComments);
		}
	};

	const isDefaultFilterState =
		currentSort === SortValues.PAGE_ORDER &&
		showInlineComments &&
		showGeneralComments &&
		currentView === 'OPEN';

	const currentFilterIcon = isDefaultFilterState ? FilterIcon : CustomSelectedFilterIcon;

	const icon = fg('confluence_frontend_comments_filter_selected_state')
		? currentFilterIcon
		: FilterIcon;

	return (
		<Flex role="tablist" xcss={dropDownMenuStyles}>
			<DropdownMenu
				trigger={({ triggerRef, ...props }) => (
					<IconButton
						testId="comments-panel-filter-button"
						ref={triggerRef}
						appearance="subtle"
						isTooltipDisabled={false}
						/**
						 * The IconButton suggests it can be given a selected state through the following
						 * `isSelected={showInlineComments && showGeneralComments && currentView !== 'OPEN'}`.
						 *
						 * From testing this does not currently work, so temporarily using a custom icon with
						 * the selected color set.
						 *
						 * Noting: This has accessibility issues (it's only providing the feedback to visual users)
						 * in addition to this the use of a button for a option selector is likely to have
						 * additional accessibility issues.
						 */
						icon={icon}
						label={formatMessage(i18n.filterAndSortLabel)}
						analyticsContext={{
							attributes: {
								name: 'filterButton',
								source: AnalyticsSource.COMMENTS_PANEL,
							},
						}}
						{...props}
					/>
				)}
				testId="filter-selector-dropdown"
				placement="bottom-end"
				spacing="compact"
				shouldRenderToParent
			>
				<DropdownItemGroup id="filter" title={formatMessage(i18n.filterTitle)}>
					{viewOptions.map((view: ViewFilter) => (
						<DropdownItem
							css={dropdownItemStyles}
							key={view.name}
							onClick={() => updateView(view.name)}
							data-testId={`menu-item-${view.name}-filter`}
							isSelected={view.name === currentView}
						>
							{formatMessage(view.label)}
						</DropdownItem>
					))}
				</DropdownItemGroup>
				<CommentSortOptions />
				<DropdownItemCheckboxGroup id="show" title={formatMessage(i18n.showTitle)} hasSeparator>
					{!fg('confluence-frontend-comments-panel-design-update') && (
						<DropdownItemCheckbox
							onClick={() => toggleShow('inline')}
							id="toggle-inline-comments"
							data-testId="toggle-inline-comments"
							isSelected={showInlineComments}
						>
							{formatMessage(i18n.inlineCommentsLabel)}
						</DropdownItemCheckbox>
					)}
					<DropdownItemCheckbox
						onClick={() => toggleShow('general')}
						id="toggle-general-comments"
						data-testId="toggle-general-comments"
						isSelected={showGeneralComments}
					>
						{formatMessage(i18n.generalCommentsLabel)}
					</DropdownItemCheckbox>
				</DropdownItemCheckboxGroup>
				<>{/* TODO: Add 'All' actions */}</>
			</DropdownMenu>
		</Flex>
	);
};

function CustomSelectedFilterIcon(props: React.ComponentProps<typeof FilterIcon>) {
	return <FilterIcon {...props} color={token('color.icon.selected')} />;
}
