import React, { useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useUnreadComments } from '@confluence/unread-comments';
import {
	useCommentsData,
	useActiveInlineCommentsQuery,
	useActiveCommentsQuery,
} from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';
import type { FlagsStateContainer } from '@confluence/flags';
import {
	useCommentsPanel,
	ViewValues,
	LocationValue,
	getUnreadCommentThreads,
} from '@confluence/comments-panel-utils';

import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';
import { CommentsPanelList } from '../components/CommentsPanelList';
import { EmptyUnreadSVG } from '../assets/EmptyUnreadSVG';
import { EmptyStateComponent } from '../components/EmptyStateComponent';

type UnreadViewProps = {
	showDeleteOption: boolean;
	flags?: FlagsStateContainer;
};

const i18n = defineMessages({
	emptyCommentsHeaderText: {
		id: 'comments-panel.empty.unread.comments.header.text',
		defaultMessage: 'No unread comments',
		description:
			'Header text to display in Unread View empty state when there are no unread comments on the page.',
	},
	emptyCommentsBodyText: {
		id: 'comments-panel.empty.unread.comments.body.text',
		defaultMessage: `Comments you haven’t read yet will appear here.`,
		description:
			'Body text to display in Unread View empty state when there are no unread comments on the page.',
	},
});

export const UnreadView = ({ showDeleteOption, flags }: UnreadViewProps) => {
	const [{ unreadCommentsListState }] = useUnreadComments();
	const { formatMessage } = useIntl();
	const [
		{ orderedActiveAnnotationIdList, commentsDataMap, removedThreadsMap },
		{ clearRemovedComments },
	] = useCommentsData();
	const [
		{ initialDataLoadedForViewMap, showInlineComments, showGeneralComments },
		{ setInitialDataLoadedForView },
	] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[ViewValues.UNREAD];

	const [contentId] = usePageContentId();

	const unreadAnnotationsToLoad = useMemo(() => {
		const unreadCommentsThreadKeys = new Set([
			...unreadCommentsListState.map((unreadComment) => unreadComment.threadKey),
		]);
		// Filter and map to get the annotation IDs that need to be loaded
		return orderedActiveAnnotationIdList
			.filter(
				(annotation) => !annotation.isLoaded && unreadCommentsThreadKeys.has(annotation.threadKey),
			)
			.map((annotation) => annotation.threadKey);
	}, [unreadCommentsListState, orderedActiveAnnotationIdList]);

	const commentsQueryHook = fg('confluence_frontend_comments_panel_v2')
		? useActiveCommentsQuery
		: useActiveInlineCommentsQuery;
	const commentsQueryHookVariables: any = fg('confluence_frontend_comments_panel_v2')
		? {
				pageId: contentId || '',
			}
		: {
				pageId: contentId || '',
				markerRefList: unreadAnnotationsToLoad,
				skip: unreadAnnotationsToLoad.length === 0,
			};

	const { loading, error, refetch } = commentsQueryHook(commentsQueryHookVariables);

	useEffect(() => {
		// When this view unmounts, we want to clear out the "resolved"/"deleted" comments
		return () => {
			clearRemovedComments();
		};
	}, [clearRemovedComments]);

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: ViewValues.UNREAD });
		}
	}, [loading, setInitialDataLoadedForView]);

	// TODO needs improvement: getting the unread comment threads here when the unread view is opened means that
	// the user might be seeing unread comments that they previously read suddenly disappear
	// since we're marking comments as read only when the user changes views
	const commentThreads = useMemo(() => {
		if (
			(!isInitialCommentDataLoaded && loading) ||
			(orderedActiveAnnotationIdList.length === 0 &&
				Object.keys(commentsDataMap.general).length === 0 &&
				Object.keys(removedThreadsMap.inline).length === 0 &&
				Object.keys(removedThreadsMap.general).length === 0 &&
				unreadCommentsListState.length === 0)
		) {
			return [];
		}

		return getUnreadCommentThreads({
			numUnreadComments: unreadCommentsListState?.length,
			commentsDataMap,
			orderedActiveAnnotationIdList,
			removedThreadsMap,
			location: LocationValue.PANEL,
			showInlineComments,
			showGeneralComments,
		});
	}, [
		loading,
		isInitialCommentDataLoaded,
		commentsDataMap,
		orderedActiveAnnotationIdList,
		removedThreadsMap,
		unreadCommentsListState?.length,
		showInlineComments,
		showGeneralComments,
	]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <Loading />;
		}

		if (error) {
			return (
				<ErrorPanel
					error={error}
					onRetryClick={async () => {
						await refetch();
					}}
				/>
			);
		}
	}

	if (commentThreads.length === 0) {
		return (
			<EmptyStateComponent
				SVG={EmptyUnreadSVG}
				emptyCommentsHeaderText={formatMessage(i18n.emptyCommentsHeaderText)}
				emptyCommentsBodyText={formatMessage(i18n.emptyCommentsBodyText)}
			/>
		);
	}

	return (
		<CommentsPanelList
			commentThreads={commentThreads}
			supportedTopLevelActions={
				showDeleteOption ? ['edit', 'resolve', 'delete'] : ['edit', 'resolve']
			}
			flags={flags}
		/>
	);
};
