import React, { useEffect, useRef } from 'react';
import { defineMessages, type MessageDescriptor } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import { ReactionsProvider } from '@confluence/comment-context';
import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { withFlags } from '@confluence/flags';
import type { WithFlagsProps } from '@confluence/flags';
import { usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';
import { useCommentsPanelState, ViewValues } from '@confluence/comments-panel-utils';
import {
	SIDEBAR_COMMENTS_PANEL_LOAD_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';

import { OpenView } from './views/OpenView';
import { UnreadView } from './views/UnreadView';
import { ResolvedView } from './views/ResolvedView';

type CommentsPanelProps = {
	currentView: ViewValues;
};

const i18n = defineMessages({
	unread: {
		id: 'comments-panel.unread.view.label',
		defaultMessage: 'Unread',
		description: 'Label for the unread view pill',
	},
	open: {
		id: 'comments-panel.open.view.label',
		defaultMessage: 'Open',
		description: 'Label for the open view pill',
	},
	resolved: {
		id: 'comments-panel.resolved.view.label',
		defaultMessage: 'Resolved',
		description: 'Label for the resolved view pill',
	},
});

export type ViewFilter = {
	label: MessageDescriptor;
	name: ViewValues;
};

export const viewOptions: ViewFilter[] = [
	{ label: i18n.open, name: ViewValues.OPEN },
	{ label: i18n.unread, name: ViewValues.UNREAD },
	{ label: i18n.resolved, name: ViewValues.RESOLVED },
];

const commentsPanelViews: Record<ViewValues, (props: any) => JSX.Element> = {
	[ViewValues.OPEN]: OpenView,
	[ViewValues.UNREAD]: UnreadView,
	[ViewValues.RESOLVED]: ResolvedView,
};

const commentPanelContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: '100%',
});

const CommentsPanelComponent = withFlags(
	({ currentView, flags }: CommentsPanelProps & WithFlagsProps) => {
		const [contentId] = usePageContentId();
		const pageContainsBodiedMacrosRef = useRef(false);

		const { currentView: userSelectedView } = useCommentsPanelState();

		const viewToShow = userSelectedView || currentView;

		// We need to check that there are page properties macros or excerpt macros on the page
		// so we can hide the delete option which causes weird issues in the document
		useEffect(() => {
			const bodiedMacroElem = document.querySelector('.ak-renderer-extension');
			pageContainsBodiedMacrosRef.current = Boolean(bodiedMacroElem);
		}, []);

		const CommentView = commentsPanelViews[viewToShow];

		return (
			<>
				<Stack xcss={commentPanelContainerStyles}>
					<ReactionsProvider contentId={contentId ?? ''}>
						<CommentView
							flags={flags}
							showDeleteOption={
								fg('confluence_frontend_nested_renderer_delete') ||
								!pageContainsBodiedMacrosRef.current
							}
						/>
					</ReactionsProvider>
				</Stack>
				<ExperienceSuccess name={SIDEBAR_COMMENTS_PANEL_LOAD_EXPERIENCE} />
			</>
		);
	},
);

export const CommentsPanel = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(CommentsPanelComponent);
