import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';

import { SortValues, useCommentsPanel } from '@confluence/comments-panel-utils';
import { fg } from '@confluence/feature-gating';

const i18n = defineMessages({
	sortTitle: {
		id: 'comments-panel.sort.title',
		defaultMessage: 'Sort',
		description: 'A title for the sort section',
	},
	pageOrder: {
		id: 'comments-panel.page-order.label',
		defaultMessage: 'Top → Bottom',
		description: 'Label for the page order sort option',
	},
	mostRecent: {
		id: 'comments-panel.most-recent.label',
		defaultMessage: 'New → Old',
		description: 'Label for the most recent sort option',
	},
});

const I18N_SORT_BY_MAP = {
	PAGE_ORDER: i18n.pageOrder,
	MOST_RECENT: i18n.mostRecent,
};

export const CommentSortOptions = () => {
	const { formatMessage } = useIntl();
	const [{ currentSort }, { setCurrentSort }] = useCommentsPanel();

	const handleSortChange = (sort: SortValues) => {
		// Do nothing if the same sort is clicked
		if (currentSort !== sort && setCurrentSort) {
			setCurrentSort(sort);
		}
	};

	const sortOptions = Object.values(SortValues).filter((sortBy) => {
		if (!fg('confluence_frontend_cp_sort_new_to_old')) {
			return sortBy !== SortValues.MOST_RECENT;
		}

		return true;
	});

	return (
		<DropdownItemGroup id="sort" title={formatMessage(i18n.sortTitle)} hasSeparator>
			{sortOptions.map((sortOption) => (
				<DropdownItem
					key={sortOption}
					onClick={() => handleSortChange(sortOption)}
					data-testId={`menu-item-${sortOption}-sort`}
					isSelected={currentSort === sortOption}
				>
					{formatMessage(I18N_SORT_BY_MAP[sortOption])}
				</DropdownItem>
			))}
		</DropdownItemGroup>
	);
};
